import baseTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui"
import { merge } from "theme-ui"

export default merge(baseTheme, {
  colors: {
    primary: "#ef6351",
    secondary: "#228183",
    heading: "#ef6351",
    divide: "#fdb700",
  },
  images: {
    my: "my-photo.jpeg"  
  },
  fonts: {
    body: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
  }
})